import React from 'react';
import { withContext } from '@context';


const Component = ({ user = {}, categories, location, children }) => {
  const { roles = {} } = user;
  const { admin = [] } = roles;
  if (admin[0] === "*") {
    return children;
  }

  const { pathname } = location;
  let path = pathname.replace('/documents', '').split("/");
  path.shift()
  path = path[0];

  const { objectId } = categories.filter(f => f.path === `/${path}`);

  if (admin.includes(objectId)) {
    return children;
  }

  return null;
}

export default withContext(['user', 'categories'])(Component);
