import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withContext } from '@context';
import { useSetState } from '@utils/hooks';
import API from '@api';
import { sort } from "@utils/helpers";
import Snackbar from '@components/Snackbar';
import Layout from '@components/Layout';
import { Login } from '@pages/auth';
import Notfound from '@pages/default';

const Component = ({
  token,
  dispatch,
  history
}) => {

  const [state, setState] = useSetState({
    loading: true,
  });

  useEffect(() => {
    init();
  }, [token]);

  useEffect(() => {
    init();
    const { pathname } = history.location;
    if (!token && pathname !== '/documents') {
      history.push('/documents');
    }
  }, [token]);

  const getActiveCagtegory = (categories) => {
    if (categories.length === 0) {
      return null;
    }

    let currentPath;
    const { pathname } = history.location;
    const path = pathname.split('/').filter(f => f !== "");

    if (pathname.startsWith('/documents') && path.length >= 1) {
      currentPath = path[1];
    }

    if (currentPath) {
      const activeCagtegory = categories.find(f => f.path === `/${currentPath}`);
      dispatch({
        type: 'CURRENT_CATEGORY_SET',
        payload: activeCagtegory,
      });

      return null;
    }

    if (categories[0]) {
      history.push(`/documents${categories[0].path}`)
    }

  }

  const init = async () => {
    const me = async () => {
      try {
        const payload = await API.me();
        await dispatch({
          type: 'USER_SET',
          payload,
        });
      } catch (e) {
        dispatch({
          type: 'SNACKBAR_ADD',
          payload: e.message,
        });
      }
    };

    const loadCategories = async () => {
      try {
        const data = await API.companies.list();
        const payload = sort(data.results, 'name');

        await dispatch({
          type: 'CATEGORIES_SET',
          payload,
        });

        getActiveCagtegory(payload);
      } catch (e) {
        dispatch({
          type: 'SNACKBAR_ADD',
          payload: e.message,
        });
      }
    }

    if (token) {
      try {
        await Promise.all([me(), loadCategories()]);
      } catch (e) {
        console.log(e.message)
      }
    }

    setState(state => {
      state.loading = false;
    });
  }

  if (state.loading) {
    return null;
  }

  return (
    <>
      {
        <Switch>
          <Redirect exact from="/" to='/documents' />
          <Route
            path="/documents"
            render={() => {
              return (
                <>
                  <Route render={(props) => {
                    return token
                      ? <Layout {...props} />
                      : <Login {...props} />
                  }} />
                </>
              )
            }}
          />
          <Route path="*" component={Notfound} />
        </Switch>
      }
      <Snackbar />
    </>
  );
};

export default withContext(['token'])(Component);
