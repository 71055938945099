const config = {
  NODE_ENV: process.env.NODE_ENV,
  APP_ID: process.env.APP_ID,
  JS_KEY: process.env.JS_KEY,
  SERVER_URL: process.env.SERVER_URL,
  BASE_URL: process.env.BASE_URL,
  JWT_SECRET: process.env.JWT_SECRET,
  JWT_EXPIRATION: process.env.JWT_EXPIRATION,
};

export default config;
