import React from 'react';
import Icon from '@components/Icon';

import { StyledNavLink } from './styles';

const Logo = ({ style }) => {
  return (
    <StyledNavLink to="/" style={style}>
      <Icon name="icon-folder" fill="#77b5f1" size={[30]} />
      Docs
    </StyledNavLink>
  );
};


export default Logo;
