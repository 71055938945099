import React, { useEffect } from "react";
import { withContext } from '@context';
import API from "@api";
import { Modal } from "@components/Modal";
import { useSetState, useInput } from "@utils/hooks";
import Button from "@components/Button";

import { Container } from "./styles";

const passwordRegex = /[^ ]{6,40}$/;

const Component = ({ closeFn, user, dispatch }) => {
  const [state, setState] = useSetState({
    oldPassword: "",
    newPassword: "",
    btnIsDisabled: true,
    isLoading: false,
  });

  useEffect(() => {
    const { oldPassword, newPassword } = state;
    let btnIsDisabled = true;

    if (oldPassword === newPassword && passwordRegex.exec(oldPassword) && passwordRegex.exec(newPassword)) {
      btnIsDisabled = false;
    }

    setState(state => {
      state.btnIsDisabled = btnIsDisabled;
    });
  }, [state.oldPassword, state.newPassword]);

  const renderOldPassword = () => {
    const [field, fieldInput] = useInput({
      component: "input",
      label: "нова парола",
      type: "password",
      value: state.oldPassword,
      isRequired: true,

      onChange: (value) => {
        const oldPassword = value.trim().toLowerCase();

        setState(state => {
          state.oldPassword = oldPassword;
        });
      }
    });

    return fieldInput;
  }

  const renderNewPassword = () => {
    const [field, fieldInput] = useInput({
      component: "input",
      label: "повтори парола",
      type: "password",
      value: state.newPassword,
      isRequired: true,

      onChange: (value) => {
        const newPassword = value.trim();

        setState(state => {
          state.newPassword = newPassword;
        });
      }
    });

    return fieldInput;
  }

  const onSave = async () => {
    setState(state => {
      state.isLoading = true;
    });

    try {
      const { username } = user;
      await API.changePassword(state.newPassword);
      const payload = await API.login({ username, password: state.newPassword });

      dispatch({
        type: 'TOKEN_SET',
        payload,
      });

      closeFn();
    } catch (e) {
      setState(state => {
        state.isLoading = false;
      });
    }
  }

  return (
    <Modal.Container size="xs">
      <Modal.Header title="Смяна на Парола" description="Задължителна дължина на паролата минимум 6 символа!" />
      <Modal.Content>
        <Container>
          {renderOldPassword()}
          {renderNewPassword()}
        </Container>
      </Modal.Content>
      <Modal.Footer>
        <Button
          onClick={closeFn}
          theme={{
            color: "grey",
            size: "md",
            borderRadius: "4px",
            minWidth: "100px",
          }}
        >Откажи</Button>
        <Button
          onClick={onSave}
          theme={{
            color: "blue",
            size: "md",
            borderRadius: "4px",
            minWidth: "100px",
            disabled: state.btnIsDisabled,
            isLoading: state.isLoading,
          }}
        >Промени</Button>
      </Modal.Footer>
    </Modal.Container>
  );
}

export default withContext(['user'])(Component);
