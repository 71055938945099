import React, { memo, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { NavLink, Link} from 'react-router-dom';
import Icon from '@components/Icon'
import ButtonComponent from './styles'

const Button = ({
  as,
  to,
  target,
  className,
  children,
  theme,
  refButton,
  onClick,
}) => {
  const {
    isLoading,
    icon,
  } = theme;
  // showLoader is used to stay in the "isLoading state" a bit longer to avoid loading flashes
  // if the loading state is too short.
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setShowLoader(true);
    }

    // Show loader a bits longer to avoid loading flash
    if (!isLoading && showLoader) {
      const timeout = setTimeout(() => {
        setShowLoader(false);
      }, 400);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isLoading, showLoader]);

  // Capture the dimensions of the button before the loading happens
  // so it doesn’t change size.
  // These hooks can be put in a seprate file.
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const innerRef = refButton || useRef(null);

  useEffect(() => {
    if (innerRef.current && innerRef.current.getBoundingClientRect().width) {
      setWidth(innerRef.current.getBoundingClientRect().width);
    }
    if (innerRef.current && innerRef.current.getBoundingClientRect().height) {
      setHeight(innerRef.current.getBoundingClientRect().height);
    }
  }, [children]);

  // Hooks used to fade in/out the loader or the button contents
  const fadeOutProps = { opacity: showLoader ? 1 : 0, width: `${width}px`, height: `${height}px` };
  const fadeInProps = { opacity: showLoader ? 0 : 1 };


  let tag = as;
  if (tag === 'NavLink') {
    tag = NavLink;
  }

  if (tag === 'Link') {
    tag = Link;
  }

  let iconContent;
  if (icon) {
    iconContent = <Icon name={icon} />;
  }

  return (
    <ButtonComponent
      as={tag}
      to={to}
      target={target}
      className={className}
      onClick={onClick}
      theme={{
        ...theme,
        showLoader,
        hasChildren: !!children,
      }}
    >
      <span
        ref={innerRef}
        style={showLoader ? fadeOutProps : fadeInProps}>
        {
          showLoader
          ? <i className="spinner" />
          : <>
              { iconContent }
              { children && <span className="button-content">{children}</span> }
            </>
        }
      </span>
    </ButtonComponent>
  );
}

const areEqual = (prevProps, nextProps) => _.isEqual(prevProps, nextProps);
export default memo(Button, areEqual);
