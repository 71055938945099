import React from "react";
import Calendar from 'react-calendar/dist/entry.nostyle';
import { Label } from './styles';

const Component = ({ maturity, onDataChange }) => {
  return (
    <>
      <Label><span>*</span>Падеж</Label>
      <Calendar
        id="maturity"
        onChange={(m) => onDataChange('maturity', m)}
        maxDetail="month"
        prev2Label={null}
        next2Label={null}
        name="maturity"
        value={maturity}
        locale="bg-BG"
        minDate={new Date()}
      />
    </>
  )
}

export default Component;
