import React from 'react';
import Dropzone from 'react-dropzone';
import { useSetState } from "@utils/hooks";
import { getFileExtOnly, prepareFileName } from '@utils/helpers';
import Icon from '@components/Icon';
import { DropzoneElement, FileIcon } from './styles';

const FileUpload = ({ onDrop }) => {
  const [state, setState] = useSetState({
    file: null
  });

  const onDropFn = (accepted, rejected) => {
    if (rejected.length > 0) {
      console.log('Може да се ъплоудва само 1 файл', rejected);
    } else if (accepted.length > 0) {
      const file = accepted.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
          params: {
            name: prepareFileName(file.name),
            extension: getFileExtOnly(file.name),
          }
        })
      })[0];

      setState(state => {
        state.file = file;
      });

      onDrop(file);
    }
  }

  return (
    <Dropzone accept=".jpg, .jpeg, .png, .pdf, .doc, .csv" onDrop={onDropFn} multiple={false}>
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <DropzoneElement
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {/* <div>
            {isDragReject && "Пусни файла тук :)"}
            {!isDragActive && "Прикачи файл"}
          </div> */}

          {
            state.file
              ? <FileIcon>
                <Icon name="icon-file" size={[100]} />
                {state.file.params.extension && <span>{`.${state.file.params.extension}`}</span>}
              </FileIcon>
              : <FileIcon>
                <Icon name="icon-file-dashed" size={[100]} />
                <Icon name="icon-upload-dashed" fill="#dadada" size={[40]} />
              </FileIcon>
          }
        </DropzoneElement>
      )}
    </Dropzone>
  );
}

export default FileUpload;
