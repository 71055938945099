import React, { useEffect } from "react";
import Parse from 'parse';
import { withContext } from "@context";
import API from "@api";
import { useSetState, useInput } from "@utils/hooks";
import { getPathName, cancellablePromise } from '@utils/helpers';
import { Modal } from "@components/Modal";
import Button from "@components/Button";
import FileUpload from '@components/FileUpload';
import Calendar from './calendar';

import { Container, Left, Right, Label } from './styles';

const Component = ({ closeFn, currentCategory, dispatch }) => {
  const [state, setState] = useSetState({
    data: {
      type: "file",
      file: null,
      name: null,
      number: null,
      desc: null,
      price: null,
      maturity: new Date(),
    },
    requiredData: ["type", "file", "name", "number", "price"],
    autoFocus: true,
    btnIsDisabled: true,
    isLoading: false,
    validationErrorMessage: ""
  });

  const onDataChange = (key, value) => {
    const { data, requiredData } = state;

    data[key] = value;

    let btnIsDisabled = false;
    for (let k in requiredData) {
      if (!btnIsDisabled) {
        if (!data[requiredData[k]] || data[requiredData[k]] === "") {
          btnIsDisabled = true;
        }
      }
    }

    setState(state => {
      state.data[key] = value;
      state.btnIsDisabled = btnIsDisabled;
    });
  };

  const isValidString = (isRequired, name) => {
    const { data, requiredData } = state;
    let isValid = true;

    if (isRequired && requiredData.includes(name)) {
      isValid = !data[name] ? data[name] !== "" : true;
    }

    return isValid;
  };

  const renderInput = (isRequired, type, name, label, rows) => {
    const isValid = isValidString(isRequired, name);

    const [field, fieldInput] = useInput({
      label,
      type,
      rows,
      value: state.data[name] || "",
      isRequired,
      autoFocus: name === "number",
      isValid,
      validationErrorMessage: "Задължително поле",
      onChange: (value) => onDataChange(name, value.trim()),
    });

    return fieldInput;
  };

  const onFileUpload = (file) => {
    onDataChange("file", file);
  };

  const onSave = async () => {
    setState(state => {
      state.isLoading = true;
    });

    const upload = new Promise((resolve, reject) => {
      const { file } = state.data;
      const {
        name,
        extension,
        size,
      } = file.params;
      const reader = new FileReader();

      reader.onloadend = async () => {
        try {
          const base64 = reader.result;
          const data = await API.file.upload({
            name,
            extension,
            base64,
          });

          const newEntry = {
            ...state.data,
            file: data,
            extension,
            size,
          };

          resolve(newEntry);
        } catch (e) {
          reject(e);
        }
      };

      reader.readAsDataURL(file);
    });

    try {
      const newEntry = await upload;
      const payload = await API.entries.create({
        companyId: currentCategory.objectId,
        data: {
          ...newEntry,
          path: getPathName()
        }
      });

      await dispatch({
        type: "ENTRIES_CREATE",
        payload,
      });

      closeFn();
    } catch (e) {
      console.log('@@@', e);
      const { response = {} } = e;
      const { status = {} } = response;

      setState(state => {
        state.isLoading = false;
        state.btnIsDisabled = true;
      });

      let payload = "Възникна грешка!";

      if (status === 409) {
        payload = `Файл с име "${state.data.name}" вече съществува`;
      }

      dispatch({
        type: "SNACKBAR_ADD",
        payload,
      });
    }
  };

  return (
    <Modal.Container size="xs">
      <Modal.Header title="Добави файл" />
      <Modal.Content>
        <Container>
          <Left>
            <FileUpload name="file" onDrop={file => onFileUpload(file)} />
          </Left>
          <Right>
            {renderInput(true, "text", "number", "номер на документа")}
            {renderInput(true, "text", "name", "име на документа")}
            {renderInput(false, "text", "desc", "описане на документа", 4)}
            {renderInput(true, "number", "price", "сума")}

            <Calendar maturity={state.data.maturity} onDataChange={onDataChange} />
          </Right>
        </Container>
      </Modal.Content>
      <Modal.Footer>
        <Button
          onClick={closeFn}
          theme={{
            color: "grey",
            size: "md",
            borderRadius: "4px",
            minWidth: "100px",
          }}
        >Откажи</Button>
        <Button
          onClick={onSave}
          theme={{
            color: "blue",
            size: "md",
            borderRadius: "4px",
            minWidth: "100px",
            disabled: state.btnIsDisabled,
            isLoading: state.isLoading,
          }}
        >Създай</Button>
      </Modal.Footer>
    </Modal.Container>
  );
};

export default withContext(["currentCategory"])(Component);
