import React, { useState, memo } from 'react';
import Button from '@components/Button';
import Menu from '@components/Menu';
import CreateNew from '@components/CreateNew';
import styled from 'styled-components';

const CreateNewButton = () => {
  const refButton = React.useRef();
  const [state, setState] = useState({
    menu: false,
  });

  const toggleVisible = (item) => {
    setState({
      [item]: !(state[item])
    });
  }

  return (
    <>
      <Button
        refButton={refButton}
        onClick={() => toggleVisible('menu')}
        theme={{
          icon: 'icon-plus',
          color: 'blue',
          size: 'sm',
          spacing: '0 0 0 auto',
        }}
      />
      <Menu visible={state.menu} refButton={refButton} closeFn={() => toggleVisible('menu')}>
        <Container>
          <Button
            onClick={() => setState({ type: 'folder' })}
            theme={{
              size: 'sm',
            }}
          >Добави директория</Button>
          <Button
            onClick={() => setState({ type: 'file' })}
            theme={{
              size: 'sm',
            }}
          >Добави файл</Button>
        </Container>
      </Menu>
      <CreateNew
        visible={state.type}
        type={state.type}
        // visible={true}
        // type={FILE}
        closeFn={() => setState({ type: null })}
      />
    </>
  );
}

export const Container = styled.div`
  min-width: 200px;

  button, a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 6px;
    height: 40px;
    color: inherit;
    font-size: 14px;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #333c48;
    }
  }
`;

export default memo(CreateNewButton);
