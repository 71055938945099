import React, { useEffect } from 'react';
import numeral from 'numeral';
import { withContext } from '@context';
import config from '@config';
import API from '@api';
import { useSetState } from '@utils/hooks';
import ShowWhenAdmin from '@utils/showWhenAdmin';
import { toLocalTime } from '@utils/helpers';
import Icon from '@components/Icon';

import { Logo, Details } from './styles';

const { NODE_ENV } = config;

const RenderPreview = ({ entry, loadListener }) => {
  const { type, extension } = entry;
  const fileExist = entry.file && entry.file.url;

  if (type !== 'file' && !fileExist) {
    return null;
  }

  let fileURL = entry.file.url;
  if (NODE_ENV === 'development') {
    fileURL = fileURL.replace('http://0.0.0.0:1337/1/files/Gk3lDeJVNJPxqs5Ie9oOSSYQdGYBLOVUCX4UMmWM/', 'http://gn1g8i93tl5hkry0dcqitqxxku95fc.files-sashido.cloud/');
  }

  let content = (
    <Details.Iframe
      title="preview"
      onLoad={loadListener}
      src={fileURL}
      frameBorder="0"
      border="0"
      cellSpacing="0"
    />
  );

  if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
    content = (
      <Details.Image
        title="preview"
        onLoad={loadListener}
        src={fileURL}
      />
    );
  }

  return (
    <Details.Right>
      <Details.Preview>
        {content}
      </Details.Preview>
    </Details.Right>
  );
}

const Component = ({ user, updateItem, entry, dispatch }) => {
  const totalItemSize = entry.size && numeral(entry.size).format('0.0 b');
  const [state, setState] = useSetState({
    isFrameLoaded: false,
    totalItemSize,
  });

  useEffect(() => {
    setFirstPreview();
  }, []);

  const setFirstPreview = async () => {
    const {
      objectId: entryId,
      creator,
      type,
      firstPreviewFrom
    } = entry;
    const { objectId: userId } = user;

    if (
      type === 'file' &&
      creator.objectId !== userId &&
      (!firstPreviewFrom || firstPreviewFrom.objectId !== userId)
    ) {
      try {
        const data = await API.entries.setFirstPreview({
          objectId: entryId,
          firstPreviewFrom: {
            __type: 'Pointer',
            className: '_User',
            objectId: userId
          },
        });

        updateItem(data);
      } catch (e) {
        console.log('@', e.message)
      }
    }
  }

  const getRouteTotalSizeHandler = async () => {
    const { path, name } = entry;
    try {
      const data = await API.entries.getTotalSize({ path: `${path}/${name}` });
      const totalItemSize = numeral(data).format('0.0 b');

      setState(state => {
        state.totalItemSize = totalItemSize;
      });
    } catch (e) {
      dispatch({
        type: 'SNACKBAR_ADD',
        payload: 'Възникна грешка!',
      });
    }
  }

  const markAsPaid = async () => {
    const { objectId } = entry;
    try {
      const data = await API.entries.markAsPaid(objectId);
      updateItem({ objectId, paid: data.paid });
    } catch (e) {
      dispatch({
        type: 'SNACKBAR_ADD',
        payload: 'Възникна грешка!',
      });
    }
  }

  const loadListener = () => {
    setTimeout(() => {
      setState(state => {
        state.isFrameLoaded = true;
      });
    }, 400);
  }

  return (
    <Details.Container>
      <Details.Left>
        <Logo type={entry.type}>
          <Icon name={`icon-${entry.type}`} size={[entry.type === "folder" ? 44 : 48]} />
          {entry.extension && <span>{`.${entry.extension}`}</span>}
        </Logo>

        <Details.Info>
          <Details.Row>
            <Details.Label>Име</Details.Label>
            <Details.Content>{entry.name}</Details.Content>
          </Details.Row>
          <Details.Row>
            <Details.Label>Описание</Details.Label>
            <Details.Content>{entry.desc || ' '}</Details.Content>
          </Details.Row>
          <Details.Row>
            <Details.Label>Дата на създаване</Details.Label>
            <Details.Content>{toLocalTime(entry.createdAt)}</Details.Content>
          </Details.Row>
          <Details.Row>
            <Details.Label>Дата на промяна</Details.Label>
            <Details.Content>{toLocalTime(entry.updatedAt)}</Details.Content>
          </Details.Row>
          <Details.Row>
            <Details.Label>Големина</Details.Label>
            <Details.Content>
              {
                state.totalItemSize || <Details.RowContentButton onClick={getRouteTotalSizeHandler}>пресметни</Details.RowContentButton>
              }
            </Details.Content>
          </Details.Row>
          <ShowWhenAdmin>
            {
              entry.type === "file" &&
              <Details.Row>
                <Details.Label>Статус на Фактурата</Details.Label>
                <Details.Content>
                  {
                    entry.paid
                      ? 'платена'
                      : <Details.RowContentButton onClick={markAsPaid}>Платена</Details.RowContentButton>
                  }
                </Details.Content>
              </Details.Row>
            }
          </ShowWhenAdmin>
        </Details.Info>
      </Details.Left>

      <RenderPreview entry={entry} loadListener={loadListener} />
    </Details.Container>
  );
}

export default withContext(['user'])(Component);
