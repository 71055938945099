import React from "react";
import _ from 'lodash';
import { GridLayout } from "@egjs/react-infinitegrid";
import EmptyState from "@components/EmptyState";

const Grid = ({
  selectedIndex,
  loadItems,
  highlightSelected,
  children,
}) => {
  const onLayoutComplete = ({ isLayout, endLoading }) => {
    if (!isLayout) {
      highlightSelected(selectedIndex);
      endLoading();
    }
  };

  let files

  if (!children || children.length === 0) {
    files = (
      <EmptyState
        title="Все още няма документи в тази директория"
      />
    );
  }

  return (
    <>
      {files}
      <GridLayout
        options={{
          isEqualSize: true,
          horizontal: false,
        }}
        layoutOptions={{
          margin: 0,
          align: "justify",
          itemSize: 140,
        }}
        onLayoutComplete={onLayoutComplete}
        onAppend={loadItems}>
        {children}
      </GridLayout>
    </>
  )
}

export default Grid;
