import styled from 'styled-components';

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 70px;
  padding: 0 20px;
  border-bottom: 1px solid #ededed;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const SearchHolder = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 300px;
  height: 40px;
  outline: none;

  > div {
    margin: 0;
  }

  input {
    border: 0;
    width: 100%;
    font-size: 14px;
    margin: 0;
    padding: 0 0 0 14px;

    &::placeholder {
      color: #afb2b6;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
  }
`;

export const SearchResultsContainer = styled.div`
  position: absolute;
  top: 140px;
  right: 0;
  left: 0;
  bottom: 0;
  height: calc(100vh - 140px);
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
  z-index: 3000;
`;

export const Line = styled.hr`
  background: #eeeff1;
  padding: 0;
  margin: -3px 0;
  opacity: 0.25;
`;

export const FilterContainer = styled.div`
  display: flex;
  padding: 10px;
  justify-content: flex-start;
`;

FilterContainer.Options = styled.div`
  border: none;

  & span {
    background: #82878b;
    padding: 2px;
    border-radius: 8px;
    color: white;
    margin: 20px 5px;
    font-size: 15px;
    cursor: pointer;
    padding: 2px 10px;
    &.selected {
      background: #051702;
    }
  }
`;

export const Result = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  white-space: nowrap;
  border-bottom: 1px solid #ededed;
  cursor: pointer;

  &:hover {
    background-color: #F3F8FF;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  color: #273142;

  > svg {
    /* margin-top: -3px; */
    margin-right: 10px;
  }
`;

export const Path = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #b1b1b1;
`;

export const Img = styled.img`
  width: 18px;
  margin-right: 10px;
`;

export const NoResult = styled.div`
  padding: 10px;
  display: flex;
  border-bottom: 2px solid rgba(238, 239, 241, 0.5);
`;
