import React, { useEffect } from 'react';
import { Container } from './styles';

const Page = () => {
  return (
    <Container>

    </Container>
  );
};

export default Page;
