import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Left = styled.div`
  width: 100px;
  margin-right: 20px;
  margin-left: -12px;
`;

export const Right = styled.div`
  flex: 1;
`;

export const Label = styled.label`
  display: block;
  font-size: 10px;
  margin-bottom: 8px;
  letter-spacing: 1px;
  color: var(--color-cs-fill-grey-2);
  text-transform: uppercase;

  span {
    font-size: 12px;
    margin-right: 2px;
  }
`;
