import React, { memo } from 'react';
import _ from 'lodash';
import { withContext } from '@context';
import ShowWhenAdmin from '@utils/showWhenAdmin';
import CreateNewButton from './CreateNewButton';
import Button from '@components/Button';
import { Container, List, Item } from './styles';

const GoUp = ({ location, prefix }) => {
  const { pathname } = location;
  const goUpLink = pathname.substring(0, pathname.lastIndexOf('/'));

  return (
    <Button
      as="NavLink"
      to={goUpLink}
      theme={{
        icon: 'icon-arrow-left',
        color: 'blue',
        size: 'sm',
        spacing: '0 14px 0 0',
        disabled: goUpLink === prefix,
      }}
    />
  )
}

const BreadcrumbLinks = ({ categories = [], history, location, prefix }) => {
  const { pathname } = location;
  const arr = pathname.replace(prefix, '').split("/");
  arr.shift();

  const len = arr.length;

  let linkItems = '';

  const listItems = arr.map((path, i) => {
    const link = linkItems = `${linkItems}/${path}`;

    const company = categories.find(f => f.path.includes(path)) || {};
    const { name } = company;

    return (
      <Item
        title={linkItems}
        key={i}
        isCurrent={len === i}
        onClick={() => {
          history.push(`${prefix}${link}`)
        }}
      >
        {name || path}
      </Item>
    )
  });

  return (
    <List>
      {categories.length > 0 && listItems}
    </List>
  )
}

const Breadcrumbs = (props) => {
  return (
    <Container>
      <GoUp {...props} />
      <BreadcrumbLinks {...props} />
      <ShowWhenAdmin>
        <CreateNewButton />
      </ShowWhenAdmin>
    </Container>
  );
};

// const areEqual = (prevProps, nextProps) => _.isEqual(prevProps, nextProps);
export default withContext(['categories'])(Breadcrumbs);
