import React, { useEffect } from "react";
import { withContext } from "@context";
import API from "@api";
import { getPathName } from '@utils/helpers';
import { useSetState, useInput } from "@utils/hooks";
import { Modal } from "@components/Modal";
import Button from "@components/Button";

const Component = ({ closeFn, currentCategory, dispatch }) => {
  const [state, setState] = useSetState({
    type: "folder",
    name: "",
    autoFocus: true,
    btnIsDisabled: true,
    isLoading: false,
    validationErrorMessage: "",
    currentCategory,
  });

  useEffect(() => {
    setState(state => {
      state.currentCategory = currentCategory;
    });
  }, [currentCategory]);

  useEffect(() => {
    const { name } = state;
    let btnIsDisabled = true;

    if (name !== "") {
      btnIsDisabled = false;
    }

    setState(state => {
      state.btnIsDisabled = btnIsDisabled;
      state.validationErrorMessage = "";
    });
  }, [state.name]);

  const renderInput = () => {
    const [field, fieldInput] = useInput({
      component: "input",
      label: "име",
      type: "text",
      value: state.name,
      isRequired: true,
      autoFocus: state.autoFocus,
      isValid: state.validationErrorMessage === "",
      validationErrorMessage: state.validationErrorMessage,
      onChange: (value) => {
        const name = value.trim();

        setState(state => {
          state.name = name;
        });
      }
    });

    return fieldInput;
  }

  const onSave = async () => {
    setState(state => {
      state.isLoading = true;
    });

    try {
      const payload = await API.entries.create({
        companyId: state.currentCategory.objectId,
        data: {
          path: getPathName(),
          name: state.name,
          type: state.type
        }
      });

      await dispatch({
        type: "ENTRIES_CREATE",
        payload,
      });

      closeFn();
    } catch (e) {
      if (e.response.status === 409) {
        setState(state => {
          state.isLoading = false;
          state.btnIsDisabled = true;
          state.validationErrorMessage = `${state.name} вече съществува`;
        });
      } else {
        dispatch({
          type: "SNACKBAR_ADD",
          payload: "Възникна грешка!",
        });
      }

    }
  }

  return (
    <Modal.Container size="xs">
      <Modal.Header title="Добави директория" />
      <Modal.Content>
        {renderInput()}
      </Modal.Content>
      <Modal.Footer>
        <Button
          onClick={closeFn}
          theme={{
            color: "grey",
            size: "md",
            borderRadius: "4px",
            minWidth: "100px",
          }}
        >Откажи</Button>
        <Button
          onClick={onSave}
          theme={{
            color: "blue",
            size: "md",
            borderRadius: "4px",
            minWidth: "100px",
            disabled: state.btnIsDisabled,
            isLoading: state.isLoading,
          }}
        >Създай</Button>
      </Modal.Footer>
    </Modal.Container>
  );
};

export default withContext(["currentCategory"])(Component);
