import styled, { keyframes, css } from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.span`
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 300;

  img {
    max-width: 100%;
  }

  svg {
    margin-right: 10px;
  }

  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 300;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  color: #fff;
  font-size: 18px;
  border: none;
  border-radius: 60px;
  background-color: #538FFF;
  transition: all .3s ease;
  cursor: pointer;


  &:hover {
    background-color: #5889E5;

    &:active {
      transform: scale(.95);
    }
  }
`;

export const Holder = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AuthLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  transition: all 1s .2s;

  a {
    color: #9CB2CD;
    font-size: 12px;
    text-decoration: none;

    &:hover {
      color: #538FFF;
    }
  }

  ${({ showAnimation }) => showAnimation && css`
    transform: translateY(-100px);
  `}
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 300px;
  padding: 30px 40px;
  border-radius: 4px;
  box-shadow: 0 0 20px -3px rgba(0,0,0, .2);
  background: #ffffff;
  overflow: hidden;
  z-index: 1;
`;

export const LoadingAnimation = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity .4s;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #0092ff;
    opacity: 0;
    pointer-events: none;
  }

  .spinner {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    border: 4px solid #66bbff;
    border-top-color: ${lighten(.2, '#66bbff')};
    border-radius: 100%;
    transform: translate(-50%, -50%);
    animation: none;
    z-index: 2;
  }

  > button {
    flex: 1;
  }

  ${({ showAnimation }) => showAnimation && css`
    > button {
      opacity: 0;
    }

    &:before {
      z-index: 1;
      ${() => css`
        animation: ${loadingAnimation} .4s forwards;
      `}
    }

    .spinner {
      display: block;
      animation: ${showSpinner} .4s forwards, ${loaderSpinner} .6s infinite linear;
    }
  `}

`;


const loadingAnimation = keyframes`
  0% { opacity: 0; transform: translate(-50%, -50%);}
  100% { opacity: 1; transform: translate(-50%, -50%) scale(80);}
`;

const loadingAnimationHide = keyframes`
  0% { opacity: 1; transform: translate(-50%, -50%) scale(80);}
  100% { opacity: 0; transform: translate(-50%, -50%);}
`;


const showSpinner = keyframes`
  /* 0% { transform: translate(-50%, -50%); } */
  100% { top: -80px }
`;


const loaderSpinner = keyframes`
  0% { transform: translate(-50%, -50%) rotate(0); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
`;
