import React, { memo, useRef, useState } from 'react';
import _ from 'lodash';
import { withContext } from '@context';
import API from '@api';
import { useSetState } from '@utils/hooks';
import Avatar from '@components/Avatar';
import Button from '@components/Button';
import Menu from '@components/Menu';
import ChangePassword from './ChangePassword';

import { UserMenuContainer, LeftPart, Name, Container } from './styles';

const Component = ({ user, dispatch, history }) => {
  const refButton = React.useRef();

  const [state, setState] = useSetState({
    showMenu: false,
    showPasswordChange: false,
  });

  const toggleMenuVisible = () => {
    setState(state => {
      state.showMenu = !state.showMenu;
    });
  }

  const togglePasswordChange = () => {
    setState(state => {
      state.showPasswordChange = !state.showPasswordChange;
    });
  }

  // console.log('user', user);

  const { fullName, avatar = {} } = user;

  const logOut = async () => {
    try {
      const data = await API.logout();
    } catch (e) {
      // console.log('@@@', e.message);
    }

    await dispatch({
      type: 'TOKEN_DELETE',
      payload: {},
    });
  }

  return (
    <>
      <UserMenuContainer>
        <LeftPart>
          <Avatar size="40" name={fullName} src={avatar.url} />
          <Name>{fullName}</Name>
        </LeftPart>
        <Button
          refButton={refButton}
          onClick={toggleMenuVisible}
          theme={{
            icon: 'icon-cog',
            color: 'gray',
            size: 'lg',
          }}
        />

        <Menu visible={state.showMenu} refButton={refButton} closeFn={toggleMenuVisible}>
          <Container>
            <Button
              onClick={logOut}
              theme={{
                size: 'sm',
              }}
            >Изход</Button>
            <Button
              onClick={() => {
                toggleMenuVisible();
                togglePasswordChange();
              }}
              theme={{
                size: 'sm',
              }}
            >Смяна на Парола</Button>
          </Container>
        </Menu>
      </UserMenuContainer>

      <ChangePassword
        visible={state.showPasswordChange}
        closeFn={() => togglePasswordChange()}
      />
    </>
  );
}


export default withContext(['user'])(Component);
