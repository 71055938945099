import React from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes, css } from 'styled-components';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import Logo from '@components/Logo'
import * as Modal from './components';

const rootExtras = document.getElementById('root-extras');

const withModal = WrappedComponent => ({ WrapperComponent = Container }) => (
  (props) => {
    const { closeFn, visible, overSidebar = true} = props;
    if (!visible) return null;

    const modalComponent = (
      <WrapperComponent overSidebar={overSidebar}>
        <Logo style={logoStyles} />
        <WrappedComponentHolder>
          <WrappedComponent {...props} />
        </WrappedComponentHolder>
        <KeyboardEventHandler
          isExclusive
          handleFocusableElements={true}
          handleKeys={['esc']}
          onKeyEvent={(key, e) => closeFn(e)} />
      </WrapperComponent>
    );

    return ReactDOM.createPortal(
      modalComponent,
      rootExtras,
    );
  }
);

export {
  withModal,
  Modal,
};

const Container = styled.div`
  position: fixed;
  left: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0;
  background-color: rgba(34, 41, 51, .9);
  z-index: 9000;

  ${() => css`
    animation: ${showEffect} .2s .1s forwards;
  `}
`;

const WrappedComponentHolder = styled.div`
  position: relative;
  height: 100vh;
`

const logoStyles = {
  position: 'absolute',
  top: '20px',
  left: '14px',
};

const ExtendedLogo = styled(Logo)`
  color: tomato;
  border-color: tomato;
`;

const showEffect = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
