import styled, { css } from 'styled-components';

export const Logo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  margin-bottom: 20px;

  ${({ type }) => type === 'file' && css`
    margin-left: -6px;
  `}

  & span {
    position: absolute;
    bottom: 16px;
    left: 11px;
    font-weight: 400;
    color: white;
    font-size: 11px;
    text-overflow: ellipsis;
    text-shadow: 0 0 2px rgba(0,0,0, .05);
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Details = {
  Container: styled.div`
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 300px;
    background-image: linear-gradient(120deg, #fff 0, #fff 300px, rgba(255,255,255, .4) 100%);
  `,
  Left: styled.div`
    width: 300px;
    padding: 40px 0 40px 40px;
  `,
  Right: styled.div`
    flex: 1;
    position: relative;
    background-color: rgba(255,255,255, .6);
  `,
  Info: styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px 0;
  `,
  Row: styled.div`
    margin-bottom: 20px;
  `,
  Label: styled.div`
    font-size: 10px;
    text-transform: uppercase;
    opacity: .4;
  `,
  Content: styled.div`
    min-height: 20px;
    font-size: 18px;
    line-height: 20px;
    font-weight: 300;
  `,
  Preview: styled.div`
    position: absolute;
    top: 40px;
    right: 40px;
    bottom: 40px;
    left: 40px;
    /* padding: 20px; */
    border-radius: 10px;
    /* border: 1px solid #ededed; */
    /* background-color: #F3F8FF; */
    /* box-shadow: 0 0 24px 0 rgba(119, 181, 241, .5); */
    overflow: hidden;
  `,
  Iframe: styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
  `,
  Image: styled.img`
    width: 100%;
    height: 100%;
    border: none;
  `,

  RowContentButton: styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    padding: 0 20px;
    color: #fff;
    font-size: 12px;
    border: none;
    border-radius: 60px;
    background-color: #538FFF;
    transition: all .3s ease;
    cursor: pointer;


    &:hover {
      background-color: #5889E5;
    }
  `,
};
