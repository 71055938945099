import React from 'react';
import { Switch } from 'react-router-dom';
import styled from 'styled-components';
import WebSockets from '@components/WebSockets';
import pages from '@pages';

import {
  SideBarContainer,
  Header,
  UserMenu,
  MenuContainer,
} from '@components/Sidebar';

const { routes, sidebar, toolbar } = pages;

const Component = () => {
  return (
    <>
      <SideBarContainer>
        <Header />
        <MenuContainer>
          {sidebar}
        </MenuContainer>
      </SideBarContainer>
      <Container>
        <UserMenu />
        <Switch>{routes}</Switch>
      </Container>
      <WebSockets />
    </>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 300px;
  overflow: hidden;
  transition: margin-left 250ms ease-in;
`;

export default Component;
