import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  min-width: 180px;
  width: 100%;
  margin-bottom: 20px;

  label {
    font-size: 10px;
    margin-bottom: 8px;
    letter-spacing: 1px;
    color: var(--color-cs-fill-grey-2);
    text-transform: uppercase;

    span {
      font-size: 12px;
      margin-right: 2px;
    }
  }

  .error-message {
    padding-top: 4px;
    color: #FE4D97;
    font-size: 12px;
    font-style: italic;
  }
`;

export const InputEl = styled.input`
  display: inline-flex;
  padding: 0 14px;
  width: 100%;
  min-height: 38px;
  color: #3E3F42;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #E8ECEF;
  background: #fff;

  ${({ isTextarea }) => isTextarea && `
    padding: 10px 14px;
    resize: none;
  `}

  &::placeholder {
    font-size: 14px;
    color: #9EA0A5;
    font-style: normal;
  }
`;
