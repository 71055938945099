import React, { useEffect } from 'react';
import { withContext } from '@context';
import { useSetState, useInput } from '@utils/hooks';
import API from '@api';
import Button from '@components/Button';
import LogoImg from '@assets/img/logo.png';

import {
  Container,
  Holder,
  Box,
  Logo,
  AuthLinks,
  LoadingAnimation,
} from './styles';

const usernameRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/;
const passwordRegex = /[^ ]{6,40}$/;

const Component = ({ user, dispatch }) => {
  const [state, setState] = useSetState({
    username: '',
    password: '',
    btnIsDisabled: true,
    showAnimation: false,
  });

  useEffect(() => {
    if (user.sessionToken) {
      location.reload();
    }
  }, [user]);

  useEffect(() => {
    const { username, password } = state;
    if (usernameRegex.exec(username) && passwordRegex.exec(password)) {
      setState(state => {
        state.btnIsDisabled = false;
      });
    }
  }, [state.username, state.password]);

  const renderUsername = () => {
    const [field, fieldInput] = useInput({
      component: 'input',
      label: 'E-мейл',
      type: "text",
      value: state.username,
      isRequired: true,

      onChange: (value) => {
        const username = value.trim().toLowerCase();

        setState(state => {
          state.username = username;
        });
      }
    });

    return fieldInput;
  }

  const renderPassword = () => {
    const [field, fieldInput] = useInput({
      component: 'input',
      label: 'парола',
      type: "password",
      value: state.password,
      isRequired: false,
      onChange: (value) => {
        const password = value.trim();

        setState(state => {
          state.password = password;
        });
      }
    });

    return fieldInput;
  }

  const handleLogin = async () => {
    setState(state => {
      state.showAnimation = true;
    });

    try {
      const { username, password } = state;
      const payload = await API.login({ username, password });

      dispatch({
        type: 'TOKEN_SET',
        payload,
      });
    } catch (e) {
      setTimeout(() => {
        setState(state => {
          state.showAnimation = false;
        });
      }, 1000);

      dispatch({
        type: 'SNACKBAR_ADD',
        payload: 'Грешен потребител или парола!',
      });
    }
  }

  return (
    <Container>
      <Holder>
        <Box>
          <Logo>
            <img src={LogoImg} alt="docs.amk-2002.bg" />
          </Logo>
          {renderUsername()}
          {renderPassword()}

          <LoadingAnimation showAnimation={state.showAnimation}>
            <Button
              onClick={handleLogin}
              theme={{
                color: 'blue',
                size: 'md',
                borderRadius: '4px',
                disabled: state.btnIsDisabled,
              }}
            >Вход</Button>
            <div className="spinner" />
          </LoadingAnimation>
        </Box>
        <AuthLinks showAnimation={state.showAnimation}>
          {/* <Link to="/register">Създай акаунт</Link> */}
          {/* <Link to="/forgot-password">Забравена парола</Link> */}
        </AuthLinks>
      </Holder>
    </Container>
  );
}

export default withContext(['user'])(Component);
